import validate from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/pages/runtime/validate.js";
import page_45global from "/home/forge/app.canteenweb.com/middleware/page.global.js";
import manifest_45route_45rule from "/home/forge/app.canteenweb.com/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/app.canteenweb.com/middleware/auth.js"),
  "check-diners": () => import("/home/forge/app.canteenweb.com/middleware/check-diners.js"),
  "diner-exists": () => import("/home/forge/app.canteenweb.com/middleware/diner-exists.js"),
  "establishment-exists": () => import("/home/forge/app.canteenweb.com/middleware/establishment-exists.js"),
  guest: () => import("/home/forge/app.canteenweb.com/middleware/guest.js")
}