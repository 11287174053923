import { default as calendargLn4MEFDRQMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/calendar.vue?macro=true";
import { default as indexMNc96XrWzjMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/index.vue?macro=true";
import { default as successZj8Ri0tMIYMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/success.vue?macro=true";
import { default as menu_45calendar3OcYrUAI6aMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/menu-calendar.vue?macro=true";
import { default as shop2oTAjPmlHkMeta } from "/home/forge/app.canteenweb.com/pages/[establishment_uuid]/shop.vue?macro=true";
import { default as accountpxhFORbKxQMeta } from "/home/forge/app.canteenweb.com/pages/account.vue?macro=true";
import { default as indexvZOjVmp4MBMeta } from "/home/forge/app.canteenweb.com/pages/add-funds/[amount]/index.vue?macro=true";
import { default as resultppKPy4vn63Meta } from "/home/forge/app.canteenweb.com/pages/add-funds/[reference]/result.vue?macro=true";
import { default as indexXLocfl3eK9Meta } from "/home/forge/app.canteenweb.com/pages/add-funds/index.vue?macro=true";
import { default as delete_45accountaCVsY2SojlMeta } from "/home/forge/app.canteenweb.com/pages/delete-account.vue?macro=true";
import { default as _91diner_uuid_93CtK29v9r5nMeta } from "/home/forge/app.canteenweb.com/pages/diners/[diner_uuid].vue?macro=true";
import { default as addGQ0mLimg9nMeta } from "/home/forge/app.canteenweb.com/pages/diners/add.vue?macro=true";
import { default as indexhpOROLNyD8Meta } from "/home/forge/app.canteenweb.com/pages/diners/index.vue?macro=true";
import { default as addIeqqvwYdc4Meta } from "/home/forge/app.canteenweb.com/pages/establishments/add.vue?macro=true";
import { default as forgot_45passwordxWBJlyGocZMeta } from "/home/forge/app.canteenweb.com/pages/forgot-password.vue?macro=true";
import { default as _91order_uuid_93P95iBxTHbeMeta } from "/home/forge/app.canteenweb.com/pages/history/orders/[order_uuid].vue?macro=true";
import { default as index1PWrJg05fOMeta } from "/home/forge/app.canteenweb.com/pages/history/orders/index.vue?macro=true";
import { default as _91transaction_uuid_93Ju5YieNsgvMeta } from "/home/forge/app.canteenweb.com/pages/history/transactions/[transaction_uuid].vue?macro=true";
import { default as indexPToCxjuMdcMeta } from "/home/forge/app.canteenweb.com/pages/history/transactions/index.vue?macro=true";
import { default as indexof0k9DmzyiMeta } from "/home/forge/app.canteenweb.com/pages/index.vue?macro=true";
import { default as loginEqEiYzaod3Meta } from "/home/forge/app.canteenweb.com/pages/login.vue?macro=true";
import { default as _91token_93d2Lodfht92Meta } from "/home/forge/app.canteenweb.com/pages/password-reset/[token].vue?macro=true";
import { default as profilelI2Wk6dWfQMeta } from "/home/forge/app.canteenweb.com/pages/profile.vue?macro=true";
import { default as signup_45successWNdZ4qSu90Meta } from "/home/forge/app.canteenweb.com/pages/signup-success.vue?macro=true";
import { default as signupJUQQ43Jf60Meta } from "/home/forge/app.canteenweb.com/pages/signup.vue?macro=true";
export default [
  {
    name: calendargLn4MEFDRQMeta?.name ?? "establishment_uuid-cart-calendar",
    path: calendargLn4MEFDRQMeta?.path ?? "/:establishment_uuid()/cart/calendar",
    meta: calendargLn4MEFDRQMeta || {},
    alias: calendargLn4MEFDRQMeta?.alias || [],
    redirect: calendargLn4MEFDRQMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/calendar.vue").then(m => m.default || m)
  },
  {
    name: indexMNc96XrWzjMeta?.name ?? "establishment_uuid-cart",
    path: indexMNc96XrWzjMeta?.path ?? "/:establishment_uuid()/cart",
    meta: indexMNc96XrWzjMeta || {},
    alias: indexMNc96XrWzjMeta?.alias || [],
    redirect: indexMNc96XrWzjMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/index.vue").then(m => m.default || m)
  },
  {
    name: successZj8Ri0tMIYMeta?.name ?? "establishment_uuid-cart-success",
    path: successZj8Ri0tMIYMeta?.path ?? "/:establishment_uuid()/cart/success",
    meta: successZj8Ri0tMIYMeta || {},
    alias: successZj8Ri0tMIYMeta?.alias || [],
    redirect: successZj8Ri0tMIYMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/cart/success.vue").then(m => m.default || m)
  },
  {
    name: menu_45calendar3OcYrUAI6aMeta?.name ?? "establishment_uuid-menu-calendar",
    path: menu_45calendar3OcYrUAI6aMeta?.path ?? "/:establishment_uuid()/menu-calendar",
    meta: menu_45calendar3OcYrUAI6aMeta || {},
    alias: menu_45calendar3OcYrUAI6aMeta?.alias || [],
    redirect: menu_45calendar3OcYrUAI6aMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/menu-calendar.vue").then(m => m.default || m)
  },
  {
    name: shop2oTAjPmlHkMeta?.name ?? "establishment_uuid-shop",
    path: shop2oTAjPmlHkMeta?.path ?? "/:establishment_uuid()/shop",
    meta: shop2oTAjPmlHkMeta || {},
    alias: shop2oTAjPmlHkMeta?.alias || [],
    redirect: shop2oTAjPmlHkMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/[establishment_uuid]/shop.vue").then(m => m.default || m)
  },
  {
    name: accountpxhFORbKxQMeta?.name ?? "account",
    path: accountpxhFORbKxQMeta?.path ?? "/account",
    meta: accountpxhFORbKxQMeta || {},
    alias: accountpxhFORbKxQMeta?.alias || [],
    redirect: accountpxhFORbKxQMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/account.vue").then(m => m.default || m)
  },
  {
    name: indexvZOjVmp4MBMeta?.name ?? "add-funds-amount",
    path: indexvZOjVmp4MBMeta?.path ?? "/add-funds/:amount()",
    meta: indexvZOjVmp4MBMeta || {},
    alias: indexvZOjVmp4MBMeta?.alias || [],
    redirect: indexvZOjVmp4MBMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/[amount]/index.vue").then(m => m.default || m)
  },
  {
    name: resultppKPy4vn63Meta?.name ?? "add-funds-reference-result",
    path: resultppKPy4vn63Meta?.path ?? "/add-funds/:reference()/result",
    meta: resultppKPy4vn63Meta || {},
    alias: resultppKPy4vn63Meta?.alias || [],
    redirect: resultppKPy4vn63Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/[reference]/result.vue").then(m => m.default || m)
  },
  {
    name: indexXLocfl3eK9Meta?.name ?? "add-funds",
    path: indexXLocfl3eK9Meta?.path ?? "/add-funds",
    meta: indexXLocfl3eK9Meta || {},
    alias: indexXLocfl3eK9Meta?.alias || [],
    redirect: indexXLocfl3eK9Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/add-funds/index.vue").then(m => m.default || m)
  },
  {
    name: delete_45accountaCVsY2SojlMeta?.name ?? "delete-account",
    path: delete_45accountaCVsY2SojlMeta?.path ?? "/delete-account",
    meta: delete_45accountaCVsY2SojlMeta || {},
    alias: delete_45accountaCVsY2SojlMeta?.alias || [],
    redirect: delete_45accountaCVsY2SojlMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/delete-account.vue").then(m => m.default || m)
  },
  {
    name: _91diner_uuid_93CtK29v9r5nMeta?.name ?? "diners-diner_uuid",
    path: _91diner_uuid_93CtK29v9r5nMeta?.path ?? "/diners/:diner_uuid()",
    meta: _91diner_uuid_93CtK29v9r5nMeta || {},
    alias: _91diner_uuid_93CtK29v9r5nMeta?.alias || [],
    redirect: _91diner_uuid_93CtK29v9r5nMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/[diner_uuid].vue").then(m => m.default || m)
  },
  {
    name: addGQ0mLimg9nMeta?.name ?? "diners-add",
    path: addGQ0mLimg9nMeta?.path ?? "/diners/add",
    meta: addGQ0mLimg9nMeta || {},
    alias: addGQ0mLimg9nMeta?.alias || [],
    redirect: addGQ0mLimg9nMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/add.vue").then(m => m.default || m)
  },
  {
    name: indexhpOROLNyD8Meta?.name ?? "diners",
    path: indexhpOROLNyD8Meta?.path ?? "/diners",
    meta: indexhpOROLNyD8Meta || {},
    alias: indexhpOROLNyD8Meta?.alias || [],
    redirect: indexhpOROLNyD8Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/diners/index.vue").then(m => m.default || m)
  },
  {
    name: addIeqqvwYdc4Meta?.name ?? "establishments-add",
    path: addIeqqvwYdc4Meta?.path ?? "/establishments/add",
    meta: addIeqqvwYdc4Meta || {},
    alias: addIeqqvwYdc4Meta?.alias || [],
    redirect: addIeqqvwYdc4Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/establishments/add.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordxWBJlyGocZMeta?.name ?? "forgot-password",
    path: forgot_45passwordxWBJlyGocZMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordxWBJlyGocZMeta || {},
    alias: forgot_45passwordxWBJlyGocZMeta?.alias || [],
    redirect: forgot_45passwordxWBJlyGocZMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: _91order_uuid_93P95iBxTHbeMeta?.name ?? "history-orders-order_uuid",
    path: _91order_uuid_93P95iBxTHbeMeta?.path ?? "/history/orders/:order_uuid()",
    meta: _91order_uuid_93P95iBxTHbeMeta || {},
    alias: _91order_uuid_93P95iBxTHbeMeta?.alias || [],
    redirect: _91order_uuid_93P95iBxTHbeMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/history/orders/[order_uuid].vue").then(m => m.default || m)
  },
  {
    name: index1PWrJg05fOMeta?.name ?? "history-orders",
    path: index1PWrJg05fOMeta?.path ?? "/history/orders",
    meta: index1PWrJg05fOMeta || {},
    alias: index1PWrJg05fOMeta?.alias || [],
    redirect: index1PWrJg05fOMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/history/orders/index.vue").then(m => m.default || m)
  },
  {
    name: _91transaction_uuid_93Ju5YieNsgvMeta?.name ?? "history-transactions-transaction_uuid",
    path: _91transaction_uuid_93Ju5YieNsgvMeta?.path ?? "/history/transactions/:transaction_uuid()",
    meta: _91transaction_uuid_93Ju5YieNsgvMeta || {},
    alias: _91transaction_uuid_93Ju5YieNsgvMeta?.alias || [],
    redirect: _91transaction_uuid_93Ju5YieNsgvMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/history/transactions/[transaction_uuid].vue").then(m => m.default || m)
  },
  {
    name: indexPToCxjuMdcMeta?.name ?? "history-transactions",
    path: indexPToCxjuMdcMeta?.path ?? "/history/transactions",
    meta: indexPToCxjuMdcMeta || {},
    alias: indexPToCxjuMdcMeta?.alias || [],
    redirect: indexPToCxjuMdcMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/history/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: indexof0k9DmzyiMeta?.name ?? "index",
    path: indexof0k9DmzyiMeta?.path ?? "/",
    meta: indexof0k9DmzyiMeta || {},
    alias: indexof0k9DmzyiMeta?.alias || [],
    redirect: indexof0k9DmzyiMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginEqEiYzaod3Meta?.name ?? "login",
    path: loginEqEiYzaod3Meta?.path ?? "/login",
    meta: loginEqEiYzaod3Meta || {},
    alias: loginEqEiYzaod3Meta?.alias || [],
    redirect: loginEqEiYzaod3Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93d2Lodfht92Meta?.name ?? "password-reset-token",
    path: _91token_93d2Lodfht92Meta?.path ?? "/password-reset/:token()",
    meta: _91token_93d2Lodfht92Meta || {},
    alias: _91token_93d2Lodfht92Meta?.alias || [],
    redirect: _91token_93d2Lodfht92Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: profilelI2Wk6dWfQMeta?.name ?? "profile",
    path: profilelI2Wk6dWfQMeta?.path ?? "/profile",
    meta: profilelI2Wk6dWfQMeta || {},
    alias: profilelI2Wk6dWfQMeta?.alias || [],
    redirect: profilelI2Wk6dWfQMeta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: signup_45successWNdZ4qSu90Meta?.name ?? "signup-success",
    path: signup_45successWNdZ4qSu90Meta?.path ?? "/signup-success",
    meta: signup_45successWNdZ4qSu90Meta || {},
    alias: signup_45successWNdZ4qSu90Meta?.alias || [],
    redirect: signup_45successWNdZ4qSu90Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/signup-success.vue").then(m => m.default || m)
  },
  {
    name: signupJUQQ43Jf60Meta?.name ?? "signup",
    path: signupJUQQ43Jf60Meta?.path ?? "/signup",
    meta: signupJUQQ43Jf60Meta || {},
    alias: signupJUQQ43Jf60Meta?.alias || [],
    redirect: signupJUQQ43Jf60Meta?.redirect || undefined,
    component: () => import("/home/forge/app.canteenweb.com/pages/signup.vue").then(m => m.default || m)
  }
]