export default defineNuxtPlugin(async () => {
  const customer = useCustomer();

  if (!customer.value) {
    return;
  }

  return {
    provide: {
      settings: useSettings().value,
    },
  };
});
