import { $fetch } from "ofetch";
// import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { Preferences } from "@capacitor/preferences";

const CSRF_COOKIE = "XSRF-TOKEN";
const CSRF_HEADER = "X-XSRF-TOKEN";
const ORGANIZATION_HEADER = "X-CANTEEN-ORGANIZATION";

export async function $larafetch(path, options = {}) {
  const { backendUrl, platform } = useRuntimeConfig().public;
  const { redirectIfNotAuthenticated = true } = options;

  // Setup CSRF token for web
  let token = null;
  if (platform === "web") {
    // token = useCookie(CSRF_COOKIE).value;
    if (
      ["post", "delete", "put", "patch"].includes(options.method?.toLowerCase())
    ) {
      await initCsrf();
      token = getCookie(CSRF_COOKIE);
    }
  }

  let headers = {
    ...options.headers,
    ...(token && { [CSRF_HEADER]: token }),
    accept: "application/json",
  };

  // Set content type to application/json if body is not FormData
  if (!(options.body instanceof FormData)) {
    headers = {
      ...headers,
      "Content-Type": "application/json",
    };
  }

  // Setup authentication headers
  if (platform === "web") {
    const organizationToken = useCookie(ORGANIZATION_HEADER).value;
    const token = useCookie("canteenAuthToken");

    if (token.value) {
      headers = {
        ...headers,
        Authorization: `Bearer ${token.value}`,
        ...(organizationToken && {
          [ORGANIZATION_HEADER]: organizationToken,
        }),
      };
    }
  } else if (platform === "mobile") {
    const organizationUuid = await Preferences.get({
      key: ORGANIZATION_HEADER,
    });

    const accessToken = await Preferences.get({
      key: "canteenAuthToken",
    });

    headers = {
      ...headers,
      ...(accessToken && {
        Authorization: `Bearer ${accessToken.value}`,
      }),
      ...(organizationUuid && {
        [ORGANIZATION_HEADER]: organizationUuid.value,
      }),
    };
  }

  options.headers = headers;
  options.baseURL = backendUrl;

  try {
    return await $fetch(path, options);
  } catch (error) {
    const status = error.response?.status;

    if (redirectIfNotAuthenticated && [401, 419].includes(status)) {
      alert("Tu sesión ha expirado, por favor inicia sesión nuevamente.");
      window.location.href = "/login";
    }

    if ([500].includes(status)) {
      console.error(
        "[Laravel Error]",
        error.response.statusText,
        error.response._data,
      );
    }
    throw error;
  }
}

async function initCsrf() {
  const { backendUrl } = useRuntimeConfig().public;
  // const existingToken = useCookie(CSRF_COOKIE).value;
  // if (existingToken) {
  //   return existingToken;
  // }

  await $fetch("/sanctum/csrf-cookie", {
    baseURL: backendUrl,
    credentials: "include",
  });
}

// https://github.com/axios/axios/blob/bdf493cf8b84eb3e3440e72d5725ba0f138e0451/lib/helpers/cookies.js
function getCookie(name) {
  const match = document.cookie.match(
    new RegExp("(^|;\\s*)(" + name + ")=([^;]*)"),
  );
  return match ? decodeURIComponent(match[3]) : null;
}
