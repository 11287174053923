import { Preferences } from "@capacitor/preferences";

export default defineNuxtPlugin(async () => {
  const { platform } = useRuntimeConfig().public;
  const customer = useCustomer();

  if (customer.value !== undefined) {
    return;
  }

  // If the user is not logged in, we don't need to refresh the token
  if (platform === "web" && !useCookie("canteenAuthToken").value) {
    return;
  } else if (platform === "mobile") {
    const canteenAuthToken = await Preferences.get({ key: "canteenAuthToken" });

    if (!canteenAuthToken) {
      return;
    }
  }

  await useAuth().refresh();
});
