export default defineNuxtRouteMiddleware(async (to, from) => {
  // ///////////////////// PAGE ANIMATION
  const getDepth = (name) => {
    // return name.split("/").filter((seg) => seg.length > 0).length;
    if (name === "index") {
      return 0;
    } else if (name === "profile") {
      return -1;
    } else {
      return 1;
    }
  };

  const toDepth = getDepth(to.name);
  const fromDepth = getDepth(from.name);

  if (toDepth > fromDepth) {
    to.meta.pageTransition = { name: "page-left" };
    from.meta.pageTransition = { name: "page-left" };
  } else {
    to.meta.pageTransition = { name: "page-right" };
    from.meta.pageTransition = { name: "page-right" };
  }
});
