/**
 * Until the official auth module is launched, we
 * are implementing: https://github.com/amrnn90/breeze-nuxt
 */
// import { SecureStoragePlugin } from "capacitor-secure-storage-plugin";
import { Preferences } from "@capacitor/preferences";
export const useAuth = () => {
  const customer = useCustomer();
  const isLoggedIn = computed(() => !!customer.value);

  const { platform } = useRuntimeConfig().public;

  async function refresh() {
    try {
      const response = await fetchSettings();
      customer.value = response.customer;
    } catch {
      customer.value = null;
    }
  }

  async function setAccessToken(payload) {
    const accessToken = payload.access_token;

    if (platform === "web") {
      const currentOrganization = useCookie("X-CANTEEN-ORGANIZATION", {
        maxAge: 365 * 24 * 60 * 60,
      });
      currentOrganization.value = payload.organization_uuid;

      const canteenAuthToken = useCookie("canteenAuthToken", {
        maxAge: 365 * 24 * 60 * 60,
      });
      canteenAuthToken.value = accessToken;
    } else if (platform === "mobile") {
      await Preferences.set({
        key: "X-CANTEEN-ORGANIZATION",
        value: payload.organization_uuid,
      });
      await Preferences.set({
        key: "canteenAuthToken",
        value: accessToken,
      });
    }
  }

  async function login(credentials) {
    if (isLoggedIn.value) {
      return;
    }

    const response = await $larafetch("/api/v1/ecommerce/login", {
      method: "post",
      body: credentials,
    });

    await setAccessToken(response);

    await refresh();

    return response;
  }

  async function register(credentials) {
    const response = await $larafetch("/api/v1/ecommerce/register", {
      method: "post",
      body: credentials,
    });

    await setAccessToken(response);

    await refresh();

    return response;
  }

  async function logout() {
    if (!isLoggedIn.value) {
      return;
    }

    await $larafetch("/api/v1/ecommerce/logout", {
      method: "post",
    });

    if (platform === "web") {
      useCookie("canteenAuthToken").value = null;
      useCookie("X-CANTEEN-ORGANIZATION").value = null;
    } else if (platform === "mobile") {
      await Preferences.remove({ key: "canteenAuthToken" });
      await Preferences.remove({ key: "X-CANTEEN-ORGANIZATION" });
    }

    window.location.href = "/?logout=true";
  }

  async function forgotPassword(email) {
    return await $larafetch("/api/v1/ecommerce/forgot-password", {
      method: "post",
      body: { email },
    });
  }

  async function resetPassword(credentials) {
    return await $larafetch("/api/v1/ecommerce/reset-password", {
      method: "post",
      body: credentials,
    });
  }

  return {
    customer,
    isLoggedIn,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
    refresh,
  };
};
