export const useSettings = () => {
  return useState("settings", () => {});
};

export const useEstablishments = () => {
  return computed(() => {
    return useSettings().value.establishments?.map((establishment) => {
      return {
        label: establishment.name,
        value: establishment.uuid,
        settings: establishment,
      };
    });
  });
};

export const useCurrentEstablishment = (establishmentUuid) => {
  return computed(() => {
    return useEstablishments().value.find((e) => e.value === establishmentUuid);
  });
};

export const fetchSettings = async () => {
  try {
    const { data } = await $larafetch("/api/v1/ecommerce/settings", {
      redirectIfNotAuthenticated: false,
    });

    useSettings().value = data;
    return data;
  } catch (error) {
    if ([401, 419].includes(error?.response?.status)) return null;
    throw error;
  }
};
