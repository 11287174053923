import { App } from "@capacitor/app";

export default defineNuxtPlugin((nuxtApp) => {
  const { platform } = useRuntimeConfig().public;
  const router = useRouter();

  if (platform === "mobile") {
    App.addListener("appUrlOpen", function (event) {
      const slug = event.url.split("https://canteenweb.com").pop();

      if (slug) {
        router.push({
          path: slug,
        });
      }
    });
  }
});
